<template>
    <div class="lg:w-3/4">
        <div class="site-content max-w-md">
            <article
                id="post-6541"
                class="post-6541 page type-page status-publish hentry"
            >
                <div class="entry-content mt-12">
                    <h2 class="text-primary"><b>How to Get Help</b></h2>
                    <p>
                        <span style="font-weight: 400"
                            >If you have a question or need technical support,
                            there are several ways to get help:</span
                        >
                    </p>
                    <p>
                        <span style="font-weight: 400">Call: </span>
                        <a
                            href="callto:480-618-5121"
                            class="font-semibold text-primary hover:underline"
                            >(480) 618-5121.</a
                        >
                    </p>

                    <section class="mt-12">
                        <span
                            class="mt-12 text-lg font-semibold text-primary py-4"
                            >Soft Open</span
                        >
                        <ul class="pl-12 m-0">
                            <li style="font-weight: 400">
                                <span style="font-weight: 400"
                                    >Friday, 12/18 1:00 - 3:00 pm EST</span
                                >
                            </li>
                            <li style="font-weight: 400">
                                <span style="font-weight: 400"
                                    >Monday, 12/21 9:00 - 11:00 am EST</span
                                >
                            </li>
                        </ul>
                    </section>
                    <section class="mt-4">
                        <span class="text-lg font-semibold text-primary py-4"
                            >Training/Preview Week</span
                        >
                        <ul class="pl-12 m-0">
                            <li style="font-weight: 400">
                                <span style="font-weight: 400"
                                    >Thursday, 1/14 12:00 - 2:00 pm EST</span
                                >
                            </li>
                            <li style="font-weight: 400">
                                <span style="font-weight: 400"
                                    >Monday, 1/18 9:00 - 11:00 am EST</span
                                >
                            </li>
                            <li style="font-weight: 400">
                                <span style="font-weight: 400"
                                    >Tuesday, 1/19 2:00 - 4:00 pm EST</span
                                >
                            </li>
                            <li style="font-weight: 400">
                                <span style="font-weight: 400"
                                    >Wednesday, 1/20 9:00 - 11:00 am EST</span
                                >
                            </li>
                            <li style="font-weight: 400">
                                <span style="font-weight: 400"
                                    >Thursday, 1/21 2:00 - 4:00 pm EST</span
                                >
                            </li>
                            <li style="font-weight: 400">
                                <span style="font-weight: 400"
                                    >Friday, 1/22 12:00 - 2:00 pm EST</span
                                >
                            </li>
                        </ul>
                    </section>
                    <section class="mt-4">
                        <span class="text-lg font-semibold text-primary py-4"
                            >Event Week</span
                        >
                        <ul class="pl-12 m-0">
                            <li style="font-weight: 400">
                                <span style="font-weight: 400"
                                    >Monday, 1/25 10:30 a.m. - 2:30 p.m. </span
                                >
                            </li>
                            <li style="font-weight: 400">
                                <span style="font-weight: 400"
                                    >Tuesday, 1/26 7 a.m. - 2 p.m. (events
                                    through 1:30; then Exhibit Hall open until
                                    6)</span
                                >
                            </li>
                            <li style="font-weight: 400">
                                <span style="font-weight: 400"
                                    >Wednesday, 1/27: 7 a.m. - 2 p.m. (events
                                    through 1:30; then Exhibit Hall open until
                                    6)
                                </span>
                            </li>
                            <li style="font-weight: 400">
                                <span style="font-weight: 400"
                                    >Thursday, 1/28 7 a.m. - 2 p.m. (events
                                    through 1:30; then Exhibit Hall open until
                                    6)</span
                                >
                            </li>
                            <li style="font-weight: 400">
                                <span style="font-weight: 400"
                                    >Friday, 1/29 9:00 a.m. - 11:00 a.m.</span
                                >
                            </li>
                        </ul>
                    </section>
                    <section class="mt-12">
                        <p>
                            <span style="font-weight: 400"
                                >If you are in the Virtual Summit and have
                                questions or are experiencing problems, you can
                                chat with a support person. Simply click on the
                                <strong> chat icon </strong> in the bottom right
                                corner of your screen.</span
                            >
                        </p>
                    </section>

                    <p>
                        <span style="font-weight: 400"
                            >You can access our virtual Help Desk. Here you can
                            speak with a staff member who can help you with your
                            tech setup, answer questions about the meeting, and
                            more. To visit the Help Desk, check the
                            <router-link class="font-bold" to="/sessions"
                                >program</router-link
                            >!</span
                        >
                    </p>
                    <p>
                        You can email directly:
                        <a
                            class="font-bold"
                            href="mailto:bespeakesupport@matrixgroup.net"
                            >bespeakesupport@matrixgroup.net</a
                        >
                    </p>

                    <!-- logging in -->
                    <h2 class="text-primary"><b>Logging In</b></h2>
                    <p>
                        <span style="font-weight: 400"
                            >Since you are accessing these FAQs from within the
                            conference platform, we assume you were able to log
                            in successfully.</span
                        >
                    </p>
                    <ul>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Can someone else log in for me?</span
                            >
                            <ul>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >No, your login information is specific
                                        to you and should be kept private. For
                                        security purposes, you can only be
                                        logged in from 1 location at a
                                        time.</span
                                    >
                                </li>
                            </ul>
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Can I access the platform before the event
                                begins to confirm my equipment will work?</span
                            >
                            <ul>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Yes, attendees will be given access to
                                        the virtual event platform beginning
                                        Monday January 18, 2021.</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >A Help Desk meeting room will be
                                        available. Visit the
                                        <router-link
                                            class="font-bold"
                                            to="/sessions"
                                            >“PROGRAM”</router-link
                                        >
                                        tab to access.</span
                                    >
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h2 class="text-primary">My Attendee Profile</h2>
                    <p>
                        As an attendee, you can update your individual profile,
                        including a photo, bio and other information.
                    </p>
                    <ul>
                        <li>
                            Navigate to the
                            <router-link to="/attendees" class="font-bold"
                                >Attendees</router-link
                            >
                            tab
                        </li>
                        <li>Click &ldquo;View My Profile&rdquo;</li>
                        <li>
                            You will be able to:
                            <ul>
                                <li>
                                    Add an avatar image 600x600 square (.png or
                                    .jpg)
                                </li>
                                <li>
                                    Edit your profile, including:
                                    <ul>
                                        <li>
                                            Email (You decide if you want this
                                            to display, by checking the
                                            &OpenCurlyDoubleQuote;display
                                            email&rdquor; box. It is NOT
                                            displayed by default)
                                        </li>
                                        <li>Title</li>
                                        <li>
                                            Phone (and decide if you want this
                                            to display, by checking the
                                            &OpenCurlyDoubleQuote;display Phone
                                            number&rdquor; box. It is NOT
                                            displayed by default)
                                        </li>
                                        <li>
                                            Add your social media account links;
                                            these will show up as icons on your
                                            profile
                                        </li>
                                        <li>Add a short bio</li>
                                        <li>
                                            Add info about your organization
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>
                            Things you cannot change:
                            <ul>
                                <li>Your name</li>
                                <li>The name of your organization</li>
                            </ul>
                        </li>
                        <li>
                            If you need to change either of these, please
                            contact ACI.
                        </li>
                    </ul>
                    <h2 class="text-primary"><b>Troubleshooting</b></h2>
                    <p>
                        <span style="font-weight: 400"
                            >How do I get help if I am having technical
                            issues?</span
                        >
                    </p>
                    <ul>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >There are several ways to get help if you are
                                having issues with the site.</span
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Click on the “HELP” tab on the top menu bar
                                (this page).</span
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Click on the help icon on the bottom right
                                corner or your screen to chat live.</span
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400">Call: </span>
                            <a
                                href="callto:480-618-5121."
                                class="font-semibold text-primary hover:underline"
                                >(480) 618-5121..</a
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Live technical support is available the days of
                                the conference. See schedule above</span
                            >
                        </li>
                    </ul>
                    <h2 class="text-primary"><b>What should I do if…</b></h2>
                    <ul>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >I cannot access a session?&nbsp;</span
                            >
                            <ul>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Please click on the help icon on the
                                        bottom right of your screen to chat live
                                        or go to the Help Desk</span
                                    >
                                </li>
                            </ul>
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >I can’t hear/see other attendees in a meeting
                                or they can’t hear/see me?&nbsp;</span
                            >
                            <ul>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Please click on the help icon on the
                                        bottom right of your screen to chat live
                                        or go to the Help Desk.</span
                                    >
                                </li>
                            </ul>
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >My audio/video is buffering.</span
                            >
                            <ul>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Check the speed on your internet
                                        connection</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Try turning off your camera. A camera
                                        can consume a lot of bandwidth</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Close all other applications running on
                                        your device.</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Make sure that your device is as close
                                        to the router as possible. If possible,
                                        use a hard-wired connection</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Click on the help icon on the bottom
                                        right of your screen to chat live.</span
                                    >
                                </li>
                            </ul>
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >I hear an echo when I talk or from other
                                attendees.</span
                            >
                            <ul>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >Try muting and then unmuting.</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >If possible, do not use the built-in
                                        microphone and speakers on your laptop.
                                        Use an external headset or
                                        speaker.</span
                                    >
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h2 class="text-primary"><b>INNOVATION HALL</b></h2>
                    <p><b>Where can I find exhibitor information?</b></p>
                    <p>
                        <span style="font-weight: 400"
                            >Click on the
                            <router-link class="font-bold" to="/tradeshow">
                                `INNOVATION HALL`
                            </router-link>
                            tab in the top menu to view additional information
                            and connect with the exhibitors.</span
                        >
                    </p>
                    <h2 class="text-primary">
                        <b>Communicating with other participants</b>
                    </h2>
                    <p><b>Where can I see who is attending?</b></p>
                    <p>
                        <span style="font-weight: 400"
                            >Click on the
                            <router-link class="font-bold" to="/attendees">
                                ‘ATTENDEES’
                            </router-link>
                            tab on the top menu bar. You can search for
                            participants by first name, last name, organization
                            or state.</span
                        >
                    </p>

                    <h2 class="text-primary">
                        <b>Can I contact or message other participants?</b>
                    </h2>
                    <p>
                        <span style="font-weight: 400"
                            >Yes, you can search for a participant by first
                            name, last name, or institution under the
                            <router-link class="font-bold" to="/attendees"
                                >‘ATTENDEES’
                            </router-link>
                            tab on the top menu bar. Then click on the ‘Send a
                            Message’ icon.</span
                        >
                    </p>
                    <!-- private meetings/Appointments -->
                    <h2 class="text-primary">
                        <b>Private Meetings/Appointments</b>
                    </h2>
                    <ul>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >The
                                <router-link class="font-bold" to="/attendees"
                                    >‘ATTENDEES’
                                </router-link>
                                tab lets you search for attendees so you can
                                schedule a meeting..</span
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400">
                                You can search for other attendees by first
                                name, last name, and organization</span
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400">
                                Once you find the person you are looking for,
                                click schedule a meeting</span
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400">
                                You will be able to invite up to 1 person for
                                each appointment</span
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400">
                                Appointments are available on
                                <router-link to="/schedule" class="font-bold"
                                    >your ‘My Schedule’ page</router-link
                                >
                                and will be held in a video room</span
                            >
                        </li>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400">
                                After you create the invitation, the people
                                invited will
                            </span>
                            <ul>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400">
                                        Get an email</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400">
                                        See a message in the message center (if
                                        they are not on the page, they will see
                                        a small number next to the Message
                                        Center tab showing that there is a
                                        message)</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400">
                                        See the invitation on their
                                        <router-link
                                            class="font-bold"
                                            to="/schedule"
                                            >‘My Schedule’
                                        </router-link>
                                        page.</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400">
                                        Invitees can accept or decline (on My
                                        Schedule); you will receive a
                                        message/email with the acceptance
                                        message or the decline.</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400">
                                        Invitees can send you a follow up
                                        message, asking to move the time or for
                                        you to add people to the meeting
                                        invitation</span
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400">
                                        As the host, you can</span
                                    >
                                    <ul>
                                        <li style="font-weight: 400">
                                            <span style="font-weight: 400">
                                                Edit the meeting invitation
                                                date/time</span
                                            >
                                        </li>
                                        <li style="font-weight: 400">
                                            <span style="font-weight: 400">
                                                Add additional invitees (up to
                                                the limit)</span
                                            >
                                        </li>
                                        <li style="font-weight: 400">
                                            <span style="font-weight: 400">
                                                Cancel the meeting
                                                invitation</span
                                            >
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <!-- end of private Meeting/Appointments -->

                    <section class="mt-8">
                        <h2 class="text-primary">
                            <b>Exhibitor Appointments</b>
                        </h2>
                        <p>
                            <span style="font-weight: 400"
                                >As an exhibitor, you will also be able to
                                create appointments with attendees.</span
                            >
                        </p>
                        <article class="p-0 md:pl-20">
                            <p class="m-0 font-semibold">Exhibitor tiers:</p>

                            <p class="m-0 font-semibold">Tier 1/Basic</p>
                            <span>
                                -- Invite up to 6 people, including the host
                            </span>

                            <p class="m-0 font-semibold">Tier 2/Bronze:</p>
                            <span class="block m-0">
                                -- Invite up to 6 people, including the host
                            </span>
                            <span>
                                -- Company profile page
                            </span>

                            <p class="m-0 font-semibold">Tier 3/Silver:</p>
                            <span class="block m-0">
                                -- Schedule meetings for up to 15 people,
                                including the host
                            </span>
                            <span class="block m-0">
                                -- Company profile page
                            </span>
                            <span class="block m-0">
                                -- Branded drop in room; part of Innovation Hall
                            </span>
                            <span class="block m-0">
                                -- Scheduled video room is branded if the
                                exhibitor uploads info; default branding is the
                                conference branding
                            </span>
                            <span class="block m-0">
                                -- Exhibitor video rooms: 8 a.m. - 10 a.m. and
                                1:30 - 3:30 p.m. Tuesday, Weds, Thurs
                            </span>

                            <p class="m-0 font-semibold">Tier 4/Gold:</p>
                            <span class="block m-0">
                                -- Schedule meetings for up to 50 people,
                                including the host
                            </span>
                            <span class="block m-0">
                                -- Company profile page
                            </span>
                            <span class="block m-0">
                                -- Branded drop in room; part of Innovation Hall
                            </span>
                            <span class="block m-0">
                                -- Scheduled video room is branded if the
                                exhibitor uploads info; default branding is the
                                conference branding
                            </span>
                            <span class="block m-0">
                                -- Exhibitor video rooms: 8 a.m. - 10 a.m. and
                                1:30 - 3:30 p.m. Tuesday, Weds, Thurs
                            </span>
                            <span class="block m-0">
                                -- Analytics reports post-conference
                            </span>
                            <span class="block m-0">
                                -- Rotating banner ad on platform during event
                            </span>

                            <p class="mt-12 font-semibold">
                                Appointments will be available Monday, Jan. 25
                                through Thursday, Jan. 28 – 24 hours a day to
                                accommodate meetings with attendees from across
                                the globe.
                            </p>
                            <p class="font-semibold">
                                Exhibitor Sessions with branded meeting rooms:
                                8:00 am.-10:00 a.m. and 1:30 p.m.-3:30 p.m.
                            </p>
                        </article>
                    </section>

                    <!-- Recorded session -->
                    <h2 class="text-primary">
                        <b>Are the sessions being recorded?</b>
                    </h2>
                    <p>
                        <span style="font-weight: 400"
                            >Yes. All live sessions in the program will be
                            recorded. They will be available in the ‘On-demand’
                            section of the Program within a day of their
                            broadcast.</span
                        >
                    </p>

                    <h1 class="mt-8">
                        <b>Best Practices For Meeting Participation</b>
                    </h1>
                    <!-- <h2><b>Equipment</b></h2> -->
                    <h3 class="text-primary">General requirements</h3>
                    <ul>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >You will need access to either a PC, Mac,
                                tablet, or smartphone. A PC or Mac is
                                recommended. To participate in the meetings, it
                                is recommended that you have an internal or
                                external camera so that you can be seen on
                                video.</span
                            >
                        </li>
                    </ul>
                    <h3 class="text-primary">Microphones</h3>
                    <ul>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >Clear audio can make a big difference.
                                Laptop/phone audio can work, but audio through
                                an external microphone (connected by wire or
                                Bluetooth) can be a huge plus. We
                                recommend:</span
                            >
                            <ul>
                                <li style="font-weight: 400">
                                    <a
                                        href="https://www.amazon.com/Logitech-Headset-H390-Noise-Cancelling/dp/B000UXZQ42/ref=psdc_3015405011_t3_B00L2JUJ68"
                                        ><b
                                            >Logitech USB Headset H390 with
                                            Noise Cancelling Mic</b
                                        ></a
                                    >
                                </li>
                                <li style="font-weight: 400">
                                    <a
                                        href="https://www.amazon.com/Education-1EDU-AE36WH-ITE-Headphone-Microphone-Single/dp/B00L2JUJ68/ref=sr_1_2?dchild=1&amp;keywords=Avid+Education+1EDU-AE36WH-+ITE+Headphone+with+Boom+Microphone%2C&amp;qid=1590542817&amp;sr=8-2"
                                        ><b
                                            >Avid Education 1EDU-AE36WH-ITE
                                            Headphone with Boom Microphone,
                                            Single Plug, White</b
                                        ></a
                                    >
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h3 class="text-primary">Browser</h3>
                    <p>
                        <span style="font-weight: 400"
                            >ACI Virtual Summit Conference will support the
                            latest versions of Chrome, Safari, Firefox, and
                            Edge. Internet Explorer 11 is not fully supported
                            and you should use a different browser.</span
                        >
                    </p>
                    <ul>
                        <li style="font-weight: 400">
                            <span style="font-weight: 400"
                                >If you plan to participate in ACI Virtual
                                Summit Annual Conference from a tablet or
                                smartphone, know that some features will be
                                harder to access, although watching sessions
                                will work just fine.</span
                            >
                        </li>
                    </ul>
                    <h2 class="text-primary">
                        <b>Accessing Private Meetings</b>
                    </h2>
                    <ul>
                        <li style="list-style-type: none">
                            <ul>
                                <li style="font-weight: 400">
                                    <span style="font-weight: 400"
                                        >There's a lot of tech going on. Please
                                        make sure you can access the private
                                        meeting rooms by visiting the
                                        <router-link
                                            class="font-bold"
                                            to="/sessions"
                                            >Help Desk</router-link
                                        >. While there:
                                        <ol>
                                            <li>
                                                Make sure you can see other
                                                people on video.
                                            </li>
                                            <li>
                                                Make sure you have given your
                                                browser permission to use your
                                                speaker and microphone.
                                            </li>
                                        </ol>
                                    </span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <!-- <ul>
                        <li style="list-style-type: none">
                            <ul>
                                <li style="list-style-type: none">
                                    <ul>
                                        <li style="font-weight: 400">
                                            <span style="font-weight: 400"
                                                >Make sure you can view the
                                                videos.</span
                                            >
                                        </li>
                                        <li style="font-weight: 400">
                                            <span style="font-weight: 400"
                                                >Make sure you have given your
                                                browser permission to use your
                                                speaker and microphone for the
                                                meeting.</span
                                            >
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul> -->
                </div>
                <!-- .entry-content -->
            </article>
            <!-- #post-6541 -->
        </div>
    </div>
</template>
<script>
export default {
    name: "help-aci"
};
</script>

<style lang="scss" scoped>
@import "../../styles/setup/colors";
@import "../../styles/views/help";
</style>
